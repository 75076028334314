<template>
  <div>
    <div id="about" class="hero">
      <v-container class="pt-8 pb-4 hero-container">
        <v-row>
          <v-col class="text-center">
            <div class="main-text">
              <h1>
                Legacy Flooring
                <span style="font-weight: 100; color: #F3F5F6"
                  >& Repair LLC.</span
                >
              </h1>
            </div>
            <div class="sub-text">
              <h2>
                Myrtle Beach, Surfside, Garden City, Murrells Inlet, Conway
              </h2>
            </div>
          </v-col>
        </v-row>
        <v-row class=".d-flex flex-nowrap image-container">
          <v-col v-for="img in images" :key="img">
            <v-img
              class="hero-img"
              height="400"
              width="200"
              :src="img.url"
              :alt="img.alt"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      images: [
        {
          url:
            "https://images.unsplash.com/photo-1560185008-b033106af5c3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
          alt: "Flooring install in Myrtle Beach, South Carolina",
        },
        {
          url:
            "https://images.unsplash.com/photo-1558944351-3f79926e74ef?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80",
          alt: "Carpet installation in Myrtle Beach, South Carolina",
        },
        {
          url:
            "https://images.unsplash.com/photo-1600566752355-35792bedcfea?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80",
          alt:
            "Professional flooring installation in Myrtle Beach, South Carolina",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.hero {
  padding-top: 2em;
  padding-bottom: 6em;
  background: #120b04;

  .hero-img {
    border-radius: 3px;
  }

  .hero-container {
    max-width: 875px;
  }
}
.image-container {
  overflow-x: auto;
}
.main-text {
  font-size: 36px;
  color: #eee;
}
.sub-text {
  font-size: 18px;
  font-weight: 100;
  color: #eee;
}
.white {
  color: #eee;
}
</style>

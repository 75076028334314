<template>
  <v-app app>
    <div id="main" class="pb-12">
      <NavBar />
      <v-main>
        <router-view></router-view>
      </v-main>
    </div>
    <v-footer :absolute="!fixed" app>
      <v-spacer></v-spacer>
      <span
        >Legacy Flooring - Myrtle Beach, South Carolina | &copy;
        {{ new Date().getFullYear() }}</span
      >
    </v-footer>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  components: {
    NavBar,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap");
html {
  scroll-behavior: smooth;
}
#main {
  font-family: "Montserrat", sans-serif;
}
</style>

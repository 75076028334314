<template>
  <div>
    <v-app-bar color="#120b04" flat dark>
      <v-app-bar-nav-icon
        class="d-md-none"
        @click="drawer = true"
        color="#972200"
      ></v-app-bar-nav-icon>

      <v-toolbar-title class="logo" style="letter-spacing: 2px"
        >Legacy Flooring | Myrtle Beach</v-toolbar-title
      >

      <v-spacer> </v-spacer>

      <div class="nav-items d-none d-md-block">
        <v-btn text href="#about">About</v-btn>
        <v-btn text href="#services">Services</v-btn>
        <v-btn text href="#contact">Contact</v-btn>
      </div>
      <v-spacer> </v-spacer>
      <v-btn small href="#contact" color="#972200">Get a Quote</v-btn>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app disable-resize-watcher>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Legacy Flooring
          </v-list-item-title>
          <v-list-item-subtitle>
            Hillsborough, NC
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item href="#about">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About</v-list-item-title>
          </v-list-item>

          <v-list-item href="#services">
            <v-list-item-icon>
              <v-icon>mdi-format-list-numbered</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Services</v-list-item-title>
          </v-list-item>

          <v-list-item href="#contact">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    fixed: false,
  }),
};
</script>

<style lang="scss" scoped></style>

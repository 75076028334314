<template>
  <div class="divider">
    <div class="text text-center">
      <a href="tel:5415880519">
        (541) 588-0519
      </a>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.divider {
  height: 100px;
  width: 100%;
  background: #972200;
}
.text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: 7d8ca3;
  font-size: 22px;
}
a {
  text-decoration: none;
  color: #fff !important;
}
a:hover {
  color: #7d8ca3 !important;
}
</style>
